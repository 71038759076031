<template>
  <ModalMain
    transition-name="none"
    @close="close(true)"
  >
    <div class="px-4 md:px-7 pt-4 w-full">
      <article class="animate-fade-in">
        <div class="text-center block">
          <h1 class="text-2xl tracking-wide font-semibold mb-2 text-center animate-slide-down-fade-in01s">Sign In</h1>
          <p v-if="!sent" class="text-slate-100 lg:text-xl mt-2 mb-6 leading-tight animate-slide-down-fade-in04s">Sign in with your email address</p>
          <p v-if="sent" class="text-slate-100 lg:text-xl mt-2 mb-6 leading-tight animate-slide-down-fade-in04s">Check your email</p>
          <input
            v-if="!sent"
            v-model="email"
            class="block relative w-full mb-4 bg-black/50 rounded-lg py-3 px-1 sm:px-3 border-2 outline-none focus:ring-0 autofill:bg-slate-800 appearance-none hover:appearance-none disabled:text-slate-100 text-sm text-center transition-all duration-200"
            name="Email"
            type="text"
            placeholder="Enter Email Address"
            autocomplete="off"
            :disabled="isLoading || sent"
            @keyup.enter="onSubmit"
          >
          <template v-if="sent && !error">
            <p class="text-center leading-tight text-gray-300 text-md mt-2 mb-6 animate-slide-down-fade-in01s">
              We've emailed a one-time link to <b>{{ email }}</b>. Click the link to sign in or register an acccount.
            </p>
          </template>
          <p v-if="error" class="text-red mb-4">
            {{ error }}
          </p>
          <ButtonButton
            v-if="!sent"
            :is-loading="isLoading"
            size="md"
            :disabled="!canSubmit"
            class="w-full"
            @click.prevent.stop="onSubmit"
          >
          Continue
          </ButtonButton>
        </div>
      </article>
    </div>
  </ModalMain>
</template>

<script>
import { mapWritableState, mapActions } from 'pinia';

export default defineComponent({
  name: 'ModalLoginEmailOneTimeLink',
  data() {
    return {
      email: null,
      loading: false,
      error: null,
      sent: false,
    };
  },
  computed: {
    ...mapWritableState(useUiStore, ['showLoginEmailOneTimeLinkModal',]),
    ...mapWritableState(useAuthStore, ['isUserLogged',]),
    isLoading() {
      return this.loading;
    },
    canSubmit() {
      return this.email && /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(this.email);
    },
  },
  watch: {
    isUserLogged(val) {
      if (val) { this.close(); }
    },
  },
  mounted() {
    if (this.email) { this.onSubmit(); }

    this.$gtmCustomEvent({ event: 'modal_show', action: 'modalOpen', modalName: 'LoginEmailOneTimeLink', });
  },
  methods: {
    ...mapActions(useAuthStore, ['loginEmailOneTimeLink', 'logout',]),
    close(abort = false) {
      this.showLoginEmailOneTimeLinkModal = false;

      if (abort) { this.logout(); }
    },
    async onSubmit() {
      this.error = null;

      try {
        this.loading = true;
        await this.loginEmailOneTimeLink(this.email?.toLowerCase()?.trim());
        this.sent = true;
      } catch (err) {
        this.email = null;
        this.sent = false;
        this.error = 'Failed to send one-time authentication link.';
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
